<script lang="ts" setup>
const { t } = useT();

defineEmits<{ (event: "toggleSidebar"): void }>();

const { open } = useAppModals();
const { data: appInitData } = useAppInitData();
const loginGuard = useLoginGuard();
const { data: luckySpinData } = useGetLuckySpinData();
const { type } = useRankedSystem();
const { data: mysteryBoxData, playMysteryBoxAudio } = useMysteryBoxAudios();
const { homePromotions } = usePromotionsData();
const { isEventBox } = useEventsCollector();
const { tournamentData } = useRace();
const { entriesActiveTournament } = useTournamentNotification();
const isGuest = useIsGuest();
const { isActiveDailyLogin } = useDailyLogin();
const { isSupercharged } = useMysteryBoxes();
const { depositInfoData } = useFunzcityDepositStreak();
const { seasonInitData } = useSeasonsData({ immediate: false });
const { isFreeSpinQuest } = useFreeSpinQuest();
const { isEventTheme } = useEventsCollector();

const isDepositStreak = computed(() => appInitData.value?.depositStreak?.isActive);
const logoImg = computed(() => `/nuxt-img/${isEventTheme.value ? "black-friday-" : ""}logo-icon.svg`);
const bannerPromo = computed(() => appInitData.value?.popupPresetPackage);
const isBestDeal = computed(() => bannerPromo?.value?.promoOfferPreset?.bestDeal);
const isMostPopular = computed(() => bannerPromo?.value?.promoOfferPreset?.mostPopular);
const badge = computed(() => parseJSON(bannerPromo?.value?.promoOfferPreset?.badgeLabel || "{}"));
const coins = computed(() => bannerPromo?.value?.promoOfferPreset?.coins);
const entries = computed(() => bannerPromo?.value?.promoOfferPreset?.entries);

const handleMagicBoxClick = () => {
	if (!isGuest.value) {
		open("LazyOModalMysteryBox");
		playMysteryBoxAudio();
	} else {
		open("LazyOModalSignup");
	}
};

const handleDepositAction = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDepositStreak");
		},
		fail: () => {
			open("LazyOModalSignup", { location: "button_right" });
		}
	});
};

const isMagicBoxAvailable = computed(
	() =>
		mysteryBoxData?.value?.isMagicBoxAvailable ||
		(!mysteryBoxData?.value?.isMagicBoxAvailable && mysteryBoxData?.value?.dailyChests?.reasonType?.includes("profile"))
);

const handleFortuneWheelClick = () => {
	open("LazyOModalWheelFortuneWheel");
};

const handleOpenCashDeposit = () => {
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${bannerPromo?.value?.promoOfferPreset?.id}/promoOffer/`);
};

const handleSeasonClick = () => {
	open("LazyOModalSeason");
};

const handleLogin = () => {
	open("LazyOModalLogin");

	dispatchGAEvent({
		event: "login",
		location: "header",
		step: "start"
	});
};

const handleSignup = () => {
	open("LazyOModalSignup");

	dispatchGAEvent({
		event: "registration",
		location: "header",
		step: "start"
	});
};

const handleDailyClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyLogin");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const showMenu = ref(true);
const SCROLL_DELAY = 300;
const lastScrollTop = ref(0);
const scrollTimer = ref<ReturnType<typeof setTimeout> | number | null>(null);

const mysteryImage = computed(() => {
	if (isEventBox.value) {
		return "/nuxt-img/dice-dash/dice-mob-menu.png";
	}

	if (isSupercharged.value) {
		return "/nuxt-img/mystery-box/super-mob-menu.png";
	}

	return "/nuxt-img/mystery-box/mystery-mob-menu.png";
});

const mysteryText = computed(() => {
	if (isEventBox.value) {
		return t("You can now open Dice Dash");
	}

	if (isSupercharged.value) {
		return t("You can now open Supercharged Box");
	}

	return t("You can NOW open Mystery Box!");
});

const handleQuestClick = () => {
	if (isFreeSpinQuest.value) {
		open("LazyOModalFreeSpin");
		return;
	}
	open("LazyOModalQuests");
};

const handleScroll = () => {
	if (scrollTimer.value) {
		clearTimeout(scrollTimer.value);
	}

	const scrollTop = window.scrollY;

	if (scrollTop > lastScrollTop.value) {
		showMenu.value = false;
	}

	showMenu.value = false;
	lastScrollTop.value = scrollTop;

	scrollTimer.value = setTimeout(() => {
		showMenu.value = true;
	}, SCROLL_DELAY);
};

onMounted(() => {
	window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener("scroll", handleScroll);
});
</script>
<template>
	<div class="mob-navigation" :class="{ 'mob-navigation__hide': !showMenu }">
		<div v-if="isDepositStreak" class="navigation-games deposit-mode" @click="handleDepositAction">
			<AText variant="tulsa" class="text-cannes text-tlalnepantla" :modifiers="['extrabold']" as="div">
				{{ !isGuest ? `+${depositInfoData?.maxPercentage}%` : t("depositStreak.mobNav.guestTitle") }}
			</AText>
			<AText variant="topeka" class="text-cannes" as="div">{{ t("depositStreak.mobNav.title") }}</AText>
			<MDepositStreakMiniFerrisWheel class="deposit-label-widget" />
		</div>
		<div
			v-else-if="luckySpinData?.wheelAvailable && !isGuest"
			class="navigation-games"
			@click="handleFortuneWheelClick"
		>
			<AText variant="tempe" :modifiers="['extrabold']" class="text-cannes">
				{{ t("You can NOW spin the wheel!") }}
			</AText>
			<MVipClubSpinnerWheel
				class="anim-wheel"
				:type="type"
				:active="luckySpinData?.wheelAvailable"
				:hideShadow="false"
				hideCounter
			/>
		</div>
		<div
			v-else-if="isActiveDailyLogin || (appInitData?.dailyLogin?.isActive && isGuest)"
			class="navigation-games daily-login"
			@click="handleDailyClick"
		>
			<AText :modifiers="['extrabold']" class="text-cannes">
				{{ t("Login & get prizes every day!") }}
			</AText>
			<NuxtImg
				class="daily-login-image"
				src="/nuxt-img/daily-login/nav-mob.png"
				alt="daily-login"
				width="200"
				height="100"
			/>
		</div>
		<div
			v-else-if="isMagicBoxAvailable || isGuest"
			class="navigation-games"
			:class="[{ 'navigation-games--jack': isEventBox }]"
			@click="handleMagicBoxClick"
		>
			<AText :modifiers="['extrabold']" :class="['text-cannes', { 'text-super': isSupercharged }]">
				{{ mysteryText }}
			</AText>
			<NuxtImg
				:class="['mistery-box-image', { 'roulette-event': isEventBox, super: isSupercharged }]"
				:src="mysteryImage"
				alt="box"
				:width="isEventBox || isSupercharged ? 194 : 220"
				:height="isEventBox || isSupercharged ? 194 : 200"
				format="avif"
				loading="lazy"
			/>
			<AAnimationSlide
				v-if="!isEventBox && !isSupercharged"
				mode="down-to-up"
				class="mistery-box-question-image"
				active
				:duration="2"
				:distance="10"
			>
				<NuxtImg class="" src="/nuxt-img/mystery-box/mob-menu-asking-mark.png" alt="quest" width="66" height="67" />
			</AAnimationSlide>
		</div>
		<div v-else-if="seasonInitData?.isEnabled && !isGuest" class="navigation-games" @click="handleSeasonClick">
			<AText :modifiers="['extrabold']" class="text-cannes">
				{{ t("Welcome to Power Tower!") }}
			</AText>
			<NuxtImg
				class="season-image"
				src="/nuxt-img/season/aside-widget.png"
				alt="box"
				width="194"
				height="194"
				format="avif"
				loading="lazy"
			/>
		</div>
		<div
			v-else-if="bannerPromo"
			class="navigation-games navigation-games--offer"
			:class="{ 'navigation-games--best': isBestDeal, 'navigation-games--most': isMostPopular }"
			@click="handleOpenCashDeposit"
		>
			<ABadge v-if="isBestDeal || isMostPopular" background="var(--goeteborg)" variant="info" autosize>
				<AText variant="ternopil" class="text-carabanchel" :modifiers="['semibold', 'uppercase']">
					{{ isBestDeal ? t("Best deal") : t("Most popular") }}
					<template v-if="badge?.percent && badge?.text"> - {{ badge?.percent }} {{ badge?.text }}</template>
				</AText>
			</ABadge>
			<AText variant="toledo" :modifiers="['uppercase', 'bold']" class="text-coro text-tlalnepantla">
				<span v-if="coins">{{ numberFormat(coins) }} FC </span>
				<span v-if="entries">+ {{ numberFormat(entries) }} free cc</span>
			</AText>
			<AButton variant="primary" size="lg" class="promo">
				<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">Buy ${{ bannerPromo.money }}</AText>
			</AButton>
		</div>
		<div class="mob-list">
			<AText as="div" variant="toledo" :modifiers="['uppercase', 'semibold']" class="mob-item mob-item__more text-coro">
				<NuxtIcon name="20/burger" filled @click="$emit('toggleSidebar')" />
				<NuxtLink v-if="isGuest" to="/">
					<NuxtImg :src="logoImg" class="logo" alt="logo" width="292" height="160" data-tid="logo" />
				</NuxtLink>
			</AText>

			<template v-if="!isGuest">
				<NuxtLink to="/issues/all-games/" class="mob-item">
					<NuxtIcon class="icon-slots" name="20/slots" filled />
					<AText variant="ternopil" :modifiers="['uppercase', 'bold']" class="text-coro">{{ t("Games") }}</AText>
				</NuxtLink>

				<NuxtLink to="/promotions/" class="mob-item">
					<NuxtIcon name="20/promotion" filled />
					<AText variant="ternopil" :modifiers="['uppercase', 'bold']" class="text-coro">{{ t("Promo") }}</AText>
					<AAnimationPulse color="var(--camaguey)" :width="18">
						<AText as="div" variant="ternopil" :modifiers="['bold']" class="alarm text-cannes">
							{{ homePromotions?.promotions?.length }}
						</AText>
					</AAnimationPulse>
				</NuxtLink>

				<NuxtLink v-if="tournamentData?.isActiveStatus" to="/races/" class="mob-item">
					<NuxtIcon name="20/races" filled />
					<AText variant="ternopil" :modifiers="['uppercase', 'bold']" class="text-coro">{{ t("Races") }}</AText>
					<AAnimationPulse v-if="entriesActiveTournament" color="var(--camaguey)" :width="18">
						<AText as="div" variant="ternopil" :modifiers="['bold']" class="alarm text-cannes"> 1 </AText>
					</AAnimationPulse>
				</NuxtLink>

				<AButton v-else class="mob-item button" @click="handleQuestClick">
					<NuxtIcon class="icon-slots" name="20/quest" filled />
					<AText variant="ternopil" :modifiers="['uppercase', 'bold']" class="text-coro">{{ t("Missions") }}</AText>
				</AButton>

				<NuxtLink to="/vip-club/" class="mob-item">
					<NuxtIcon class="icon-slots" name="30/vip-gradient" filled />
					<AText variant="ternopil" :modifiers="['uppercase', 'bold']" class="text-coro">{{ t("VIP Club") }}</AText>
				</NuxtLink>
			</template>

			<div v-else class="buttons-auth">
				<AButton variant="secondary" size="lg" class="login-btn" @click="handleLogin">
					<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">{{ t("Log in") }}</AText>
				</AButton>
				<AButton variant="primary" size="lg" class="signup-btn" @click="handleSignup">
					<AText variant="toledo" :modifiers="['semibold']" class="text-tlalnepantla">{{ t("Sign up") }}</AText>
				</AButton>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.mob-navigation {
	position: fixed;
	z-index: 20;
	bottom: 0;
	width: 100%;
	height: 68px;
	transition: 0.3s;

	&__hide {
		bottom: -160px;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.buttons-auth {
	display: flex;
	gap: gutter(1);

	button {
		width: 85px;
	}

	.login-btn {
		border-radius: 8px;
	}
}

.mob-list {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--carabanchel);
	box-shadow: 0px -10px 17px 0px rgba(0, 0, 0, 0.32);
	border-radius: 12px 12px 0 0;
	padding: gutter(0.75) gutter(2);
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 20;

	.logo {
		width: 80px;
		height: 44px;

		@media (max-width: 360px) {
			display: none;
		}
	}
}

.navigation-games {
	position: absolute;
	z-index: 19;
	left: 0;
	bottom: calc(100% - 10px);
	width: 100%;
	height: 60px;
	padding: 16px 16px 0;
	display: flex;
	align-items: flex-start;
	background: var(--coyoacan);
	border-radius: 12px 12px 0 0;

	&.deposit-mode {
		flex-direction: column;
		padding: 4px 16px;
		background: var(--goerlitz);

		.deposit-label-widget {
			position: absolute;
			right: 8px;
			top: -40px;
			z-index: 1;
			width: 91px;
			height: 100px;
		}
	}

	&--offer {
		height: 68px;
		flex-direction: column;
		padding: gutter(1.25) gutter(2) 0;
		gap: gutter(0.5);

		.promo {
			position: absolute;
			top: 10px;
			right: 16px;
		}
	}

	&--best {
		background: var(--guaruja);
	}

	&--most {
		background: var(--guliston);
	}

	&--jack {
		@media (max-width: 420px) {
			padding: 0 16px;
			align-items: center;
			& > span {
				max-width: calc(100% - 130px);
				position: relative;
				margin-top: -5px;
			}
		}
	}

	&:hover {
		cursor: pointer;
	}

	&.daily-login {
		background: var(--gyumri);
	}
}

.anim-wheel {
	position: absolute;
	right: 16px;
	top: -8px;
	z-index: 1;
}

.mistery-box-question-image {
	position: absolute;
	animation-iteration-count: infinite;
	right: 16px;
	top: -16px;
}

.mistery-box-image {
	position: absolute;
	right: 0;
	top: -6px;
	width: 110px;
	height: 90px;

	&.roulette-event,
	&.super {
		top: -28px;
		width: 130px;
		height: 130px;
	}
}

.daily-login-image {
	width: 100px;
	height: 50px;
	position: absolute;
	right: 0px;
	top: 0px;
}

.season-image {
	width: 92px;
	height: 92px;
	position: absolute;
	right: 0;
	top: -25px;
}

.mob-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	font-weight: 600;
	min-width: 40px;
	position: relative;

	&__more {
		flex-direction: row;
		gap: gutter(3);
	}

	&:deep(.bold) {
		line-height: 1;
	}

	.nuxt-icon {
		margin-bottom: auto;
	}

	.nuxt-icon,
	.notification-center {
		font-size: 30px;
		text-align: center;
		vertical-align: middle;
		border-radius: 5px;
		margin-bottom: gutter(0.25);
		color: var(--coro);
	}

	.notification-center:deep(.icon) {
		color: var(--coro);
	}

	&.button {
		border: none;
		padding: 0;
	}

	&.router-link-active {
		.nuxt-icon {
			color: var(--chihuahua);
		}

		span.ternopil {
			background-image: var(--ghaziabad);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.alarm {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute !important;
		top: 0;
		right: 2px;
		width: 17px;
		height: 17px;
		border-radius: 50%;
		border: 1px solid var(--cannes);
		background-color: var(--coyoacan);
	}
}

.text-super {
	max-width: 200px;
	transform: translateY(-6px);
}
</style>
